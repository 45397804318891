import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import { uniqueId } from 'lodash';

import { DataField } from './schemas';
import { ArrayHelpers } from './types';

export const arrayHelpers: ArrayHelpers = {
  insertAt: (arr, data, index) => {
    const copy = [...arr];
    const innerIdx =
      index === 'start' ? 0 : index === 'end' ? arr.length : index;

    copy.splice(innerIdx, 0, data);
    return copy;
  },
  removeAt: (arr, index) => {
    const copy = [...arr];
    copy.splice(index, 1);
    return copy;
  },
  updateAt: (arr, data, index) => {
    const copy = [...arr];
    const innerIdx =
      index === 'start' ? 0 : index === 'end' ? arr.length : index;
    copy.splice(innerIdx, 1, data);
    return copy;
  },
};

const DEFAULT_CREATE_INITIAL_VALUE = {
  type: null,
  question: '',
  key: '',
  required: false,
  position: null,
  hint: '',
};

export const createFormBuilderInitialValue = ({
  isEdit,
  dataFieldId,
  dataFields = [],
}: {
  isEdit: boolean;
  dataFieldId: string | null;
  dataFields: Array<DataField>;
}) => {
  if (isEdit) {
    if (!dataFieldId || dataFields.length === 0) {
      throw new Error(`Missing datafields and/or dataFieldId`);
    }
    const dataField = dataFields.find(
      ({ id, _id }) => id === Number(dataFieldId) || _id === dataFieldId,
    );

    if (!dataField) {
      throw new Error(`Missing datafield to update`);
    }

    switch (dataField.type) {
      case ReviewStageDataFieldTypeEnum.textField:
        return dataField;
      case ReviewStageDataFieldTypeEnum.radioField:
        return {
          ...dataField,
          // the _id field is added on the FE to account for the key field during the so the ordering
          // is not messed up during removals. The BE does not assign a id field to options so in the case
          // of an edit we must assign the option fields an _id so the FE can track it during removals
          options: dataField.options.map(({ _id, ...opt }) => ({
            _id: (_id ?? uniqueId()) as string,
            ...opt,
          })),
        };
      default:
        throw new Error(
          // @ts-expect-error ignore that it is never here as we want to log the value that was missed
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `initial value creation for type: ${dataField.type} not implemented`,
        );
    }
  }

  return DEFAULT_CREATE_INITIAL_VALUE;
};
